section.auth {
    .complete {
        p {
            text-align: center;
            font-size: 18px;
            a {
                color: var(--bg-dark);
            }
        }
    }
}