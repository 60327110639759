.application {
    overflow: scroll;
    .header {
        padding: 15px 0;
        display: flex;
        flex-direction: row;
        align-items: center;
        h4 {
            font-style: normal;
            font-weight: 500;
            font-size: 28px;
            color: #020e23;
        }
        .buttons {
            flex-grow: 1;
            display: flex;
            flex-direction: row;
            justify-content: flex-end;
            button,
            a {
                min-width: 120px;
                margin: 0 5px;
                height: 35px;
                border-radius: 5px;
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: center;
                text-decoration: none;
                box-sizing: border-box;
                img {
                    margin-right: 10px;
                }
                cursor: pointer;
            }
            button:focus,
            a:focus {
                outline: none;
            }
            a {
                background-color: #F1503D;
            }
        }
    }
    .triggers {
        display: flex;
        flex-direction: row;
        justify-content: center;
        margin: 15px auto;
        button {
            margin: 0 5px;
            padding: 5px 10px;
            min-width: 120px;
            height: 35px;
            border-radius: 5px;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            text-decoration: none;
            box-sizing: border-box;
            border: 1px solid transparent;
            img {
                margin-right: 10px;
            }
            cursor: pointer;
        }
        button:first-child {
            color: var(--bg-dark);
            background-color: #fff;
            border-color: var(--bg-dark);
        }
        button:last-child {
            color: #fff;
            background-color: var(--bg-dark);
        }
    }
    tr:hover {
        background-color: #f4f4f4;
    }
}