section.auth {
    .verify {
        .form-input {
            display: flex;
            flex-direction: column;
            justify-content: center;
            label {
                text-align: center;
            }
            input {
                max-width: 200px;
                padding: 10px 0;
                text-align: center;
                letter-spacing: 3px;
                font-size: 20px;
                margin: 10px auto;
            }
        }
    }
}
