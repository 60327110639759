.application {
    .camps {
        .records {
            display: flex;
            flex-direction: column;
            flex-wrap: wrap;
            justify-content: center;
            .camp {
                display: flex;
                flex-direction: row;
                margin: 7px;
                min-width: 300px;
                background-color: #fff;
                border-radius: 10px;
                padding: 20px;
                h4 {
                    color: #262626;
                }
                span {
                    width: 100px;
                    text-align: right;
                    color: #FC0D1B;
                    font-weight: 700;
                }
                > div {
                    flex-grow: 1;
                }
                h4, p {
                    margin-bottom: 5px;
                    span {
                        font-weight: 500;
                    }
                }
                button {
                    color: #020e23;
                    background-color: #fff;
                    font-size: 14px;
                    padding: 2px 5px;
                    border-radius: 3px;
                    border: 1px solid #020e23;
                    cursor: pointer;
                }
            }
        }
        @media (min-width: 700px) {
            .records {
                flex-direction: row;
                justify-content: flex-start;
            }
        }
    }
}