.application {
    .students {
        .records {
            display: flex;
            flex-direction: column;
            flex-wrap: wrap;
            justify-content: center;
            .student {
                display: flex;
                flex-direction: row;
                margin: 10px;
                min-width: 350px;
                h4 {
                    color: #262626;
                }
                img {
                    width: 100px;
                    height: 100px;
                    border-radius: 10px 0 0 10px;
                }
                > div {
                    padding: 10px;
                    background-color: #fff;
                    border-radius: 0 10px 10px 0;
                    flex-grow: 1;
                }
                h4, p {
                    margin-bottom: 5px;
                    span {
                        font-weight: 500;
                    }
                }
            }
        }
        @media (min-width: 700px) {
            .records {
                flex-direction: row;
                justify-content: flex-start;
            }
        }
    }
}