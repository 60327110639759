.application {
    .add-camp {
        width: 100%;
        padding: 10px;
        border-radius: 10px;
        form {
            .actions {
                button {
                    flex-basis: 48%;
                }
            }
        }
    }
    @media (min-width: 700px) {
        .add-camp {
            max-width: 450px;
        }
    }
}
